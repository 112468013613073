import React, { Component } from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    text-align: center;
    padding: 150px 30px 100px;
  
`;
const Heading = styled.h2`
  margin-bottom:30px;
  font-weight: 700;
  font-size: 50px;
  text-transform: uppercase;
`
const Paragraph = styled.p`
padding-top: 20px;
font-size: 20px;
`

export default class Maintenance extends Component {

  
  render() {
    return (
      <Wrapper >
        <Heading>under constraction</Heading>
        <img src='/assets/images/under_construction.jpg'/>
        <Paragraph>Our website is under construction, but We are still working on it !</Paragraph>
      </Wrapper>
    )
  }
};

