

import Maintenance from './page/Maintenance';
import Layout from './layout/Layout';


function App() {

  const maintenance = true;

  return (

    <div >
      {!maintenance ? <Layout/> : <Maintenance/>}
    </div>



  );
}

export default App;
